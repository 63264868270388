import {
  BOOK_TRIAL_CLASS_REF,
  REGISTER_REF,
  SIGNUP_SOURCE_LOCAL_STORAGE_KEY,
} from '@common/constants'
import RatelimitErrorModal from '@components/auth/ratelimit-error-modal.dynamic'
import BookTrialClassForm from '@components/book-trial-class/form'
import AuthModal from '@containers/competitions/auth-modal.dynamic'
import { UserProps, useSessionContext } from '@contexts/session'
import useSegment, {
  getSegmentTrackingDict,
  SegmentEventEnum,
} from '@hooks/useSegment'
import { ROLES } from '@lib/enums/dashboard'
import { FB_EVENTS } from '@lib/enums/tracking'
import { showNoLaptopWarning } from '@lib/utils/auth'
import { getFromLocalStorage } from '@lib/utils/local-storage'
import { useRouter } from 'next/router'
import React, { FormEvent, useEffect, useState } from 'react'
import { HasLaptopFieldType } from '@lib/types/geo-lp'
import { ENGLISH_CONTENT } from '@lib/constants/geo-contents/english'
import useLazyRecaptcha from '@hooks/useLazyRecaptcha'
import LazyRecaptcha from '@components/common/lazy-recaptcha'
import { LinguiJSMessageDescriptor } from '@lib/types/common'
import { msgT } from '@lib/utils/i18n'
import { t } from '@lingui/macro'
import { checkSupportedDevice } from '@lib/utils/system-check'
import { GRADES, GradeType } from '@components/common/forms/grade-select'

const {
  heroFold: {
    form: {
      gradeFieldPlaceholder: DEFAULT_GRADE_PLACEHOLDER,
      phoneFieldLabel: DEFAULT_PHONE_LABEL,
      hasLaptopField: DEFAULT_HASLAPTOP_LABELS,
    },
  },
} = ENGLISH_CONTENT

type Props = {
  next?: string
  cta?: string | LinguiJSMessageDescriptor
  openOtpVerificationModal?: boolean
  handleOtpVerified?: () => Promise<any>
  countryCode?: string
  shouldHaveFormShadow?: boolean
  formHeading?: string
  phoneFieldLabel?: string | LinguiJSMessageDescriptor
  gradeFieldPlaceholder?: string | LinguiJSMessageDescriptor
  termsAndConditions?: string
  hasLaptopFieldLabels?: HasLaptopFieldType<string | LinguiJSMessageDescriptor>
  isRightToLeftPage?: boolean
  trialCourseUrl?: string
  isMathTrial?: boolean
}

type Query = {
  phone: string
  source: string
  next?: string
  course?: string
}

declare global {
  interface Window {
    grecaptcha: any
  }
}

const BookTrialClassContainer = ({
  next,
  handleOtpVerified,
  openOtpVerificationModal = false,
  countryCode,
  shouldHaveFormShadow,
  formHeading = '',
  phoneFieldLabel = DEFAULT_PHONE_LABEL,
  gradeFieldPlaceholder = DEFAULT_GRADE_PLACEHOLDER,
  cta,
  termsAndConditions,
  isRightToLeftPage,
  hasLaptopFieldLabels = DEFAULT_HASLAPTOP_LABELS,
  trialCourseUrl,
  isMathTrial,
}: Props) => {
  const router = useRouter()
  const { loggedIn, name, email, gender, phone, student } = useSessionContext()
  const track = useSegment()
  const [isAutoRegister, setIsAutoRegister] = useState(false)
  // Disable form fields only on promo-pages (gpay, phonepay etc)
  // adding true and false because `&&` operator in TS picks the typeof second operand which is `string` in this case
  const LOGGEDIN_PROMO_PAGE = loggedIn && next ? true : false

  const [trialBookingData, setTrialBookingData] = useState({
    // User form inputs
    phone: LOGGEDIN_PROMO_PAGE ? phone : '',
    name: LOGGEDIN_PROMO_PAGE ? name : 'Student',
    email: LOGGEDIN_PROMO_PAGE ? email : '',
    role: ROLES.STUDENT,
    childName: '',
    childGrade: {
      value: LOGGEDIN_PROMO_PAGE ? student?.grade : 'select',
      label: LOGGEDIN_PROMO_PAGE ? student?.grade : msgT(gradeFieldPlaceholder),
    },
    childGender: {
      value: LOGGEDIN_PROMO_PAGE ? gender : null,
      label: LOGGEDIN_PROMO_PAGE ? gender : 'Unspecified',
    },
    // If current device is mobile, users are shown the option
    // to select whether they have a laptop or not.
    hasLaptop: checkSupportedDevice().device === 'desktop' ? true : null,
    termsAndConditions: true,
    whatsappEnabled: true,
    signupSource:
      (typeof window !== 'undefined' &&
        getFromLocalStorage(SIGNUP_SOURCE_LOCAL_STORAGE_KEY)) ||
      router.asPath.split('?')[0],

    // Server response
    response: {},
    errors: {},
    loading: false,
  })

  const [showRatelimitModal, setShowRatelimitModal] = useState(false)
  const [isOtpVerificationModalOpen, setIsOtpVerificationModalOpen] = useState(
    false
  )
  const [activeRecaptchaFlow, setActiveRecaptchaFlow] = useState<
    'signup' | 'login'
  >('signup')
  const {
    recaptchaAPI,
    setShouldRecaptchaLoad,
    shouldRecaptchaLoad,
    setRecaptchaAPI,
  } = useLazyRecaptcha()

  const source =
    router.pathname === '/register' ? REGISTER_REF : BOOK_TRIAL_CLASS_REF

  // Set course = Specialised Trialcourse URL if present
  const course = trialCourseUrl && trialCourseUrl

  const changeTrialBookingData = (data) => {
    setTrialBookingData((prevData) => ({ ...prevData, ...data }))
    // Any change in trialBookingData will trigger loading of reCaptcha
    setShouldRecaptchaLoad(true)
  }

  const handleInputChange = (elem: FormEvent<HTMLInputElement>): void => {
    let value: string | boolean = elem.currentTarget.value

    if (elem.currentTarget.name === 'hasLaptop') {
      value = elem.currentTarget.value === 'yes'

      if (!value) showNoLaptopWarning()
    }

    changeTrialBookingData({
      [elem.currentTarget.name]: value,
    })
  }

  const handlePhoneInputChange = (value: string) => {
    changeTrialBookingData({
      phone: value,
      email: `${value}@codingal-student.com`,
    })
  }

  const handleSelectChange = (
    id: string,
    value: {
      value: string
      label: string
    }
  ) => {
    changeTrialBookingData({
      [id]: value,
    })
  }

  // const handleCheckboxChange = (elem: FormEvent<HTMLInputElement>): void => {
  //   changeTrialBookingData({
  //     [elem.currentTarget.name]: elem.currentTarget.checked,
  //   })
  // }

  // handle OTP request reCAPTCHA trigger here
  const handleOtpRequestCaptcha = async (recaptchaKey: string) => {
    // If the reCAPTCHA code is null or undefined indicating that
    // the reCAPTCHA was expired then return early
    if (!recaptchaKey) return

    const authUtils = await import('@containers/auth/utils')

    const formBody = authUtils.prepareRegistrationFormBody(trialBookingData)
    recaptchaAPI.reset()

    formBody['recaptchaResponse'] = recaptchaKey
    const loginResponse = await authUtils.requestLoginOtp(formBody)

    const currentQuery = router.query
    if (course) {
      delete currentQuery.course
    }
    const query: Query = {
      ...currentQuery,
      phone: trialBookingData.phone,
      source,
      ...(course ? { course } : {}),
    }

    // Check if user already exists with same phone number
    if (loginResponse.status === 200) {
      // Will verify OTP on the same page
      if (openOtpVerificationModal) setIsOtpVerificationModalOpen(true)
      else {
        if (next) query.next = next
        router.push({
          pathname: '/login/verify-otp/',
          query,
        })
      }
    } else if (loginResponse.status === 204) {
      changeTrialBookingData({ loading: false })
      setActiveRecaptchaFlow(() => 'signup')
      await recaptchaAPI.execute()
    } else if (loginResponse.status === 406) {
      changeTrialBookingData({
        errors: { token: [t`Error verifying reCAPTCHA, please try again`] },
        loading: false,
      })
    } else {
      const error = await loginResponse.json()
      changeTrialBookingData({
        errors: error,
        loading: false,
      })
    }
  }

  // handle form submit here
  const handleTrialBookingFormSubmit = async (
    e: FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault()

    if (trialBookingData.loading || !recaptchaAPI) return

    const currentQuery = router.query

    if (LOGGEDIN_PROMO_PAGE) {
      delete currentQuery.next
      router.push({
        pathname: next,
        query: currentQuery,
      })
      return
    }

    const validators = await import('@containers/book-trial-class/validators')
    const authUtils = await import('@containers/auth/utils')

    changeTrialBookingData({
      loading: true,
    })

    // Validate client side form inputs,and if any return early
    const errors = validators.validateTrialBookingFormData(trialBookingData)
    if (Object.keys(errors).length) {
      changeTrialBookingData({
        errors,
        loading: false,
      })
      return
    }

    const formBody = authUtils.prepareRegistrationFormBody(trialBookingData)
    try {
      const loginResponse = await authUtils.requestLoginOtp(formBody)
      // if exiting user, go the OTP verification
      if (loginResponse.status === 200) {
        // Will verify OTP on the same page
        if (openOtpVerificationModal) setIsOtpVerificationModalOpen(true)
        else {
          if (course) {
            delete currentQuery.course
          }
          const query: Query = {
            ...currentQuery,
            phone: trialBookingData.phone,
            source,
            ...(course ? { course } : {}),
          }
          if (next) query.next = next

          router.push({
            pathname: '/login/verify-otp/',
            query,
          })
        }
      } else if (loginResponse.status === 204) {
        // If user closes the recaptcha challenge, the CTA is stuck in
        // loading state. Setting loading to false prevents this.
        changeTrialBookingData({ loading: false })
        // if new user, execute the sign up reCAPTCHA
        setActiveRecaptchaFlow(() => 'signup')
        await recaptchaAPI.execute()
      } else if (loginResponse.status === 429) {
        // If user closes the recaptcha challenge, the CTA is stuck in
        // loading state. Setting loading to false prevents this.
        changeTrialBookingData({ loading: false })
        // if OTP generate rate limit (10r/h) is hit, execute OTP generate reCAPTCHA
        setActiveRecaptchaFlow(() => 'login')
        await recaptchaAPI.execute()
      } else if (loginResponse.status === 400) {
        const error = await loginResponse.json()
        changeTrialBookingData({
          errors: error,
          loading: false,
        })
      } else {
        const utilsCommon = await import('@lib/utils/common')
        throw new Error(
          await utilsCommon.getAPIErrorString(
            loginResponse,
            loginResponse.url,
            formBody
          )
        )
      }

      // catch any other login errors here
    } catch (error) {
      const commonUtils = await import('@common/utils')
      commonUtils.showErrorAlert({
        text: t`There was an internal error. Please try again later or contact support if the error persists.`,
      })
      const Sentry = await import('@sentry/node')
      Sentry.captureException(error)
    }
  }

  // handle user creation reCAPTCHA trigger here
  const handleSignupCaptcha = async (recaptchaKey: string) => {
    // the reCAPTCHA was expired then return early
    if (!recaptchaKey) return

    const authUtils = await import('@containers/auth/utils')

    const formBody = {
      ...authUtils.prepareRegistrationFormBody(trialBookingData),
      recaptchaResponse: recaptchaKey,
    }

    recaptchaAPI.reset()

    const signupResponse = await authUtils.registerUser(formBody)

    // if register is successful
    // On successful registration auth token is set
    if (signupResponse.status === 201) {
      if (window.fbq) {
        const data: UserProps = await signupResponse.json()
        window.fbq(
          'trackCustom',
          FB_EVENTS.SIGNUP_COMPLETED,
          {},
          { eventID: data?.id?.toString() }
        )
        track(
          SegmentEventEnum.CONVERSION_SIGNUP_COMPLETED,
          getSegmentTrackingDict(data)
        )
      }
      // In CE-862 we removed OTP verification, so calling this here after token is set.
      handleOtpVerified && (await handleOtpVerified())

      const currentQuery = router.query
      if (course) {
        delete currentQuery.course
      }
      const query: Query = {
        ...currentQuery,
        phone: trialBookingData.phone,
        source,
        ...(course ? { course } : {}),
      }
      if (next) {
        delete query.next
        router.push({ pathname: next, query })
        return
      }

      delete query?.phone

      router.push({
        pathname: '/dashboard/',
        query,
      })
      changeTrialBookingData({
        loading: false,
      })
      // If user creation rate limit(10r/d) is hit, show the rate limit error modal
    } else if (signupResponse.status === 429) {
      setShowRatelimitModal(true)
      changeTrialBookingData({
        loading: false,
      })
      // The user has been blocked, show blocked alert
    } else if (signupResponse.status === 403) {
      const commonUtils = await import('@common/utils')
      commonUtils.showErrorAlert({
        text: t`You have been blocked. Please contact support if you think it is a mistake.`,
      })
      changeTrialBookingData({
        loading: false,
      })
      // If any other register errors then handle here
    } else {
      const error = await signupResponse.json()
      changeTrialBookingData({
        errors: error,
        loading: false,
      })
    }
  }

  /** ReCaptcha's onChange function. Triggered when recaptchaAPI.execute() is called. */
  const handleRecaptchaOnChange = async (recaptchaKey: string) => {
    changeTrialBookingData({ loading: true })
    activeRecaptchaFlow === 'signup'
      ? await handleSignupCaptcha(recaptchaKey)
      : await handleOtpRequestCaptcha(recaptchaKey)
  }

  useEffect(() => {
    const phoneFromQueryParams = router?.query?.phone
    const gradeFromQueryParams = router?.query?.grade
    const hasLaptopFromQueryParams = router?.query?.hasLaptop
    hasLaptopFromQueryParams &&
      changeTrialBookingData({ hasLaptop: hasLaptopFromQueryParams })
    setIsAutoRegister(router?.query?.autoRegister ? true : false)

    // decode the hashed phone mu
    const decodePhone = (encodedPhone) => {
      try {
        return decodeURIComponent(atob(encodedPhone))
      } catch (error) {
        console.error('Failed to decode phone number:', error)
        return null
      }
    }

    if (phoneFromQueryParams) {
      const decodedPhone = decodePhone(phoneFromQueryParams)
      if (decodedPhone) {
        handlePhoneInputChange(decodedPhone)
      }
    }

    if (
      gradeFromQueryParams &&
      GRADES.some((grade) => grade.value === gradeFromQueryParams)
    ) {
      changeTrialBookingData({
        childGrade: {
          value: gradeFromQueryParams,
          label: gradeFromQueryParams, // Assuming the label is the same as value in this context
        },
      })
    } else {
      setIsAutoRegister(false)
    }
  }, [])

  const handleGradeSelect = (grade: GradeType) => {
    changeTrialBookingData({ childGrade: grade })
  }

  return (
    <>
      <LazyRecaptcha
        onChange={handleRecaptchaOnChange}
        setRecaptchaAPI={setRecaptchaAPI}
        shouldRecaptchaLoad={shouldRecaptchaLoad}
      />
      <BookTrialClassForm
        {...trialBookingData}
        handleInputChange={handleInputChange}
        handlePhoneInputChange={handlePhoneInputChange}
        handleSelectChange={handleSelectChange}
        // handleCheckboxChange={handleCheckboxChange}
        handleTrialBookingFormSubmit={handleTrialBookingFormSubmit}
        LOGGEDIN_PROMO_PAGE={LOGGEDIN_PROMO_PAGE}
        ctaText={cta}
        termsAndConditions={termsAndConditions}
        countryCode={countryCode}
        shouldHaveFormShadow={shouldHaveFormShadow}
        formHeading={formHeading}
        phoneFieldLabel={phoneFieldLabel}
        hasLaptopFieldLabels={hasLaptopFieldLabels}
        isRightToLeftPage={isRightToLeftPage}
        handleGradeSelect={handleGradeSelect}
        isAutoRegister={isAutoRegister}
        isMathTrial={isMathTrial}
      />
      <RatelimitErrorModal
        isOpen={showRatelimitModal}
        setIsOpen={setShowRatelimitModal}
      />
      <AuthModal
        phone={trialBookingData.phone}
        isOpen={isOtpVerificationModalOpen}
        setIsOpen={setIsOtpVerificationModalOpen}
        handleOtpVerified={handleOtpVerified}
        redirectionUrl={next}
      />
    </>
  )
}

export default BookTrialClassContainer
